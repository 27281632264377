import React, { useEffect } from 'react';

const AdUnit = ({ adClient, adSlot }) => {
    useEffect(() => {
        // Load the AdSense script
        const script = document.createElement('script');
        script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${adClient}`;
        script.async = true;
        script.crossOrigin = 'anonymous';
        document.body.appendChild(script);

        // Push the ad to adsbygoogle array after the script loads
        script.onload = () => {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        };

        return () => {
            // Cleanup the script if the component is unmounted
            document.body.removeChild(script);
        };
    }, [adClient]);

    return (
        <ins
            className="adsbygoogle"
            style={{ display: 'block', width: '200px', height: '1150px' }} // Example size
            data-ad-client={adClient}
            data-ad-slot={adSlot}
            data-ad-format="rectangle" // Specify the format
        ></ins>
    );
};

export default AdUnit;
